import Swiper from 'swiper/js/swiper.min.js'

let teamInit = new Swiper('.dhsv_vc_team_slider .swiper-container', {
  effect: 'slide',
  allowTouchMove: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  loop: false,
  autoHeight: true,
  pagination: {
    el: '.team_pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.team_next',
    prevEl: '.team_prev',
  },
  on: {
    init: function() {
      let activeIndex = this.activeIndex
      this.slides
        .eq(activeIndex)
        .addClass('swiper-slide-visible_member')
        .next()
        .addClass('swiper-slide-visible_member')
        .next()
        .addClass('swiper-slide-visible_member')
    },
    slideChange: function() {
      let activeIndex = this.activeIndex
      this.slides.removeClass('swiper-slide-visible_member')
      this.slides
        .eq(activeIndex)
        .addClass('swiper-slide-visible_member')
        .next()
        .addClass('swiper-slide-visible_member')
        .next()
        .addClass('swiper-slide-visible_member')
    },
  },
  breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 2,
    },
    // when window width is >= 992px
    992: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      allowTouchMove: false,
    },
  },
})

$(window).load(function() {
  if ($('.dhsv_vc_team_slider').length) {
    teamInit.init()
    teamInit.update()
  }
  if (
    $(
      '.dhsv_vc_team_slider .swiper-container .swiper-slide:not(.swiper-slide-duplicate)'
    ).length == 1
  ) {
    $('.dhsv_vc_team_slider .swiper-button-next').addClass('disabled')
    $('.dhsv_vc_team_slider .swiper-button-prev').addClass('disabled')
  }
})

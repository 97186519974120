//Slide Down/Up content
function vc_toggle_publications() {
  const $wrapper = $('.dhsv_publications').find('ul')
  let visibleHeight = 0
  $wrapper.find('li:lt(6)').each(function() {
    visibleHeight += $(this).outerHeight(true, true)
  })
  $wrapper.css('height', visibleHeight)
}

$(window).load(function() {
  vc_toggle_publications()
})

$(window).resize(function() {
  vc_toggle_publications()
})

$('.dhsv_publications').on('click', '.toggler_button .btn', function() {
  const $wrapper = $(this).closest('.dhsv_publications')
  $wrapper.toggleClass('disable')
  if ($wrapper.hasClass('disable')) {
    let fullHeight = 0
    $wrapper.find('li').each(function() {
      fullHeight += $(this).outerHeight(true, true)
    })
    $wrapper.find('ul').css('height', fullHeight)
    $('.toggler_button span').text('Weniger')
  } else {
    vc_toggle_publications()
    $('.toggler_button span').text('Alles anzeigen')
  }
})

import Swiper from 'swiper/js/swiper.min.js'

let progressInit = new Swiper('.progress_slider', {
  effect: 'fade',
  allowTouchMove: false,
  slidesPerView: 1,
  loop: false,
  autoHeight: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: {
      el: '.dhsv_vc_progress_slider__description',
      slidesPerView: 1,
      effect: 'fade',
      allowTouchMove: false,
      autoHeight: true,
    },
  },
})

$(window).load(function() {
  if ($('.dhsv_vc_progress_slider').length) {
    progressInit.init()
    progressInit.update()
    dotsProgress()
  }
})

let dotsProgress = function() {
  $('.dhsv_vc_progress_slider').each(function() {
    const slidesCount = $(this).find('.progress_slider .swiper-slide').length
    let num = 1
    while (num - 1 < slidesCount) {
      $(this)
        .find('.dots_progress')
        .append('<span>' + num + '<span></span></span>')
      num++
    }

    $(document)
      .find('.dots_progress > span:first-of-type')
      .addClass('active')
  })
}

let activeDot = function(el) {
  let $currentSlider = el.closest('.dhsv_vc_progress_slider')
  let activeSlide =
    $(document)
      .find($currentSlider)
      .find('.progress_slider .swiper-slide.swiper-slide-active')
      .index() + 1
  $currentSlider.find('.dots_progress > span').removeClass('active')
  $currentSlider
    .find('.dots_progress > span:lt(' + activeSlide + ')')
    .addClass('active')
}

$('.dhsv_vc_progress_slider').on('click', '.arrow', function() {
  activeDot($(this))
})

$(document).on('click', '.dots_progress span', function() {
  progressInit.slideTo($(this).index())
  activeDot($(this))
})

import $ from 'jquery'

$(document).ready(dhsv_vc_hero_section())

function dhsv_vc_hero_section() {
  $('.next_section').on('click', function() {
    let heroHeight = $(this)
      .closest('.dhsv_vc_hero_section')
      .outerHeight()
    $('html, body').animate(
      {
        scrollTop: heroHeight,
      },
      1000
    )
  })
}

$('.dhsv_vc_study').on('click', '.expand_content', function() {
  let $parent = $(this).closest('.study__item_info')
  if ($parent.hasClass('opened')) {
    $parent.removeClass('opened')
    $parent.addClass('closed')
    $parent.find('.study__excerpt').slideUp()
  } else {
    $parent.addClass('opened')
    $parent.removeClass('closed')
    $parent.find('.study__excerpt').slideDown()
  }
})

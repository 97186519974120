import 'jquery-match-height/dist/jquery.matchHeight-min'
import stickElement from '../components/stickit'

export default {
  init() {
    // JavaScript to be fired on all pages

    const GformLabel = function() {
      $('.gform_wrapper')
        .find('.ginput_container_checkbox')
        .each(function() {
          $(this)
            .closest('li')
            .find('> label')
            .css('display', 'none')
        })
    }

    const fixedHeader = function() {
      if ($(window).scrollTop() > 0) {
        $('.banner').addClass('fixed')
      } else {
        $('.banner').removeClass('fixed')
      }
    }

    //Sticky Footer
    const bumpIt = function() {
      $('body').css('padding-bottom', $('.footer').outerHeight(true))
      $('.footer').addClass('sticky-footer')
    }

    $(window).resize(function() {
      bumpIt()
    })

    setInterval(function() {
      bumpIt()
    }, 250)

    // Full width Row
    function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,
          marginLeft: -document.body.clientWidth / 2,
        })

        if (!$(this).attr('data-vc-stretch-content')) {
          let padding =
            (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
          $(this).css({
            'padding-left': padding,
            'padding-right': padding,
          })
        }
      })
    }

    resizeFullWidthRow()

    //header Search

    $('.js-search-button-desktop').on('click', function() {
      $('.searchform-wrapper-desktop').toggleClass('visible')
    })

    $('.js-search-button-mobile').on('click', function() {
      $('.searchform-wrapper-mobile').toggleClass('visible')
    })

    //navigation icon
    $('.navbar-toggler').on('click', function() {
      $(this).toggleClass('open')
      $('.banner').toggleClass('menu_opened')
      if ($(this).hasClass('open')) {
        $('.menu-block')
          .slideDown(300)
          .css('display', 'flex')
      } else {
        $('.menu-block').slideUp(300)
      }
    })

    //breadcrumbs rebuild

    function breadcrumbs_rebuild() {
      let homeLink = $('.breadcrumbs')
        .find('.home:not(.current-item)')
        .closest('span')
      homeLink.remove()
      let angle = $('.breadcrumbs > i:first-of-type')
      angle.remove()
    }

    breadcrumbs_rebuild()

    function external_links() {
      $('p a[href^=http]').each(function() {
        if (this.href.indexOf(location.hostname) == -1) {
          $(this)
            .addClass('external_link')
            .attr({
              target: '_blank',
              title: 'Opens in a new window',
            })
        }
      })
    }

    external_links()

    //smooth_scroll
    function smooth_scroll(link, e) {
      if (e) {
        e.preventDefault()
        if (e.isTrigger) {
          return false
        }
      }
      var hash = false
      if (!link && window.location.hash) {
        hash = window.location.hash
      } else if (link && link.indexOf('#') == 0) {
        hash = link
      } else if (
        link &&
        location.pathname.replace(/^\//, '') ==
          link.pathname.replace(/^\//, '') &&
        location.hostname == link.hostname
      ) {
        hash = link.hash
      }
      if (hash) {
        let scrollDistance = 0
        if ($(window).width() <= 767) {
          scrollDistance =
            $(hash).offset().top -
            $('.section-anchors__list').outerHeight() -
            $('.banner').outerHeight() -
            $('.js-mobile-anchor-toggle').outerHeight()
        } else {
          scrollDistance =
            $(hash).offset().top -
            $('.section-anchors__list').outerHeight() -
            $('.banner').outerHeight()
        }
        $('html, body').animate(
          {
            scrollTop: scrollDistance,
          },
          600
        )
        if ($(window).scrollTop() == 0) {
          setTimeout(function() {
            $('html, body').animate(
              {
                scrollTop:
                  $(hash).offset().top -
                  $('.section-anchors__list').outerHeight(),
              },
              600
            )
          }, 500)
        }
        history.replaceState(null, null, hash)
      }
    }

    $(document).on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-vc-tabs],[role="tab"])',
      function(e) {
        smooth_scroll($(this).attr('href'), e)
      }
    )
    if (window.location.hash) {
      smooth_scroll(window.location.hash)
    }

    $(window).on('resize', function() {
      resizeFullWidthRow()
    })

    $(document).ready(function() {
      $('.matchHeight').matchHeight()
      $('.matchHeight-2').matchHeight()

      $(window).scroll(function() {
        if (
          $(window).scrollTop() + $(window).height() ==
          $(document).height()
        ) {
          $('.scrollToTop').show()
          $('.fixed_contacts').hide()
        } else {
          $('.scrollToTop').hide()
          $('.fixed_contacts').show()
        }
      })

      $('.scrollToTop').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 1000)
        return false
      })

      /*Gform columns styles*/
      $(
        ':not(.gfmc-column) + .gfmc-column, * > .gfmc-column:first-of-type'
      ).each(function() {
        $(this)
          .nextUntil(':not(.gfmc-column)')
          .addBack()
          .wrapAll('<ul class="gfmc-wrapper" />')
      })
    })

    $(window).on('load', function() {
      // fade-in effect on page load
      setTimeout(function() {
        $('body').removeClass('fade-out')
      }, 1)

      fixedHeader()

      GformLabel() //Hide label for checkbox field
    })

    $(window).on('scroll', function() {
      fixedHeader()
    })

    $(document).on('gform_confirmation_loaded', function(event, formId) {
      let formOffset = $('#gf_' + formId).offset().top
      $('html, body').animate(
        {
          scrollTop:
            formOffset -
            $('.section-anchors__list').outerHeight() -
            $('.banner').outerHeight() -
            50,
        },
        600
      )
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    stickElement('.section-anchors')
    stickElement('.banner')
  },
}

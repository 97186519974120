import $ from 'jquery'

$(document).ready(dhsv_vc_button())

function dhsv_vc_button() {
  $('.dhsv_vc_button').on('click', '.btn--toggle', function() {
    $(this)
      .next('.hidden_content')
      .slideToggle()
  })
}

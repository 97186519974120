/*Load more*/

$(document).on('click', '.dhsv_vc_magazin_list .load-more', function() {
  /*global ajax*/
  var pageId = $('.dhsv_vc_magazin_list').data('id')
  var data = {
    action: 'load_more',
    pageId: pageId,
    visible: $('.dhsv_vc_magazin_list').find('.page__item').length,
  }
  $.ajax({
    type: 'POST',
    data: data,
    dataType: 'json',
    url: ajax.url,
  }).done(function(res) {
    $('.dhsv_vc_magazin_list')
      .find('.row.project_loop')
      .append(res.html)
    if (res.show_button) {
      $('.dhsv_vc_magazin_list')
        .find('.load-more')
        .show()
    } else {
      $('.dhsv_vc_magazin_list')
        .find('.load-more')
        .hide()
    }
  })
})

let borders = function() {
  $('.dhsv_vc_team_grid').each(function() {
    let count = $(this).find('.grid_item').length
    let rowItems = 4

    if ($(document).width() <= 1200 && $(document).width() > 991) {
      rowItems = 3
    } else if ($(document).width() <= 991 && $(document).width() > 576) {
      rowItems = 2
    } else if ($(document).width() <= 576) {
      rowItems = 1
    }
    let lastItems = count % rowItems
    let lastBlocks = $(this).find('.grid_item')

    if (lastItems == 0) {
      lastBlocks
        .removeClass('no-border')
        .slice(lastBlocks.length - rowItems)
        .addClass('no-border')
    } else {
      lastBlocks
        .removeClass('no-border')
        .slice(lastBlocks.length - lastItems)
        .addClass('no-border')
    }
  })
}

$(window).on('resize', function() {
  borders()
})

$(document).ready(function() {
  borders()
})

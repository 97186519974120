import $ from 'jquery'

$(document).ready(dhsv_vc_page_anchors())

function dhsv_vc_page_anchors() {
  var $pageAnchors = $('.section-anchors')
  var handleAnchorSwitch = $anchor => {
    $pageAnchors.find('.js-current-anchor').text($anchor.text())
  }

  let offset = 0

  if ($(window).width() <= 767) {
    offset = 365
  } else {
    offset = 200
  }

  $('body').scrollspy({
    target: '#section-anchors',
    offset: offset,
  })

  $(window)
    .on('hashchange', function() {
      var hash = location.hash
      $pageAnchors.find('a').each(function() {
        var that = $(this)
        that[that.attr('href') === hash ? 'addClass' : 'removeClass']('active')
      })
    })
    .on('activate.bs.scrollspy', function(e, obj) {
      var target = (obj && obj.relatedTarget) || null
      if (target === null) {
        target = document.activeElement
      }
      handleAnchorSwitch($('a[href="' + target + '"]'))
    })

  $('.js-mobile-anchor-toggle').on('click', function() {
    let $this = $(this)
    let $parent = $this.closest('.section-anchors')
    let togglerHeight = $this.outerHeight()
    let scroll = 0
    let elHeight = $this.closest('.set-sticky__holder').attr('data-height')

    $(this)
      .closest('.section-anchors')
      .find('.section-anchors__list')
      .slideToggle()

    if ($this.hasClass('opened')) {
      let anchorsHeight =
        $this.closest('.section-anchors').outerHeight() - togglerHeight
      if ($parent.hasClass('set-sticky')) {
        scroll = $(window).scrollTop() + anchorsHeight
        setTimeout(function() {
          $('html, body').animate(
            {
              scrollTop: scroll,
            },
            400
          )
        }, 400)
      } else {
        $this.closest('.set-sticky__holder').animate({ height: elHeight }, 400)
      }
    } else {
      if ($parent.hasClass('set-sticky')) {
        setTimeout(function() {
          let anchorsHeight =
            $this.closest('.section-anchors').outerHeight() - togglerHeight
          scroll = $(window).scrollTop() - anchorsHeight
          $('html, body').animate(
            {
              scrollTop: scroll,
            },
            400
          )
        }, 400)
      } else {
        $this.closest('.set-sticky__holder').css('height', 'auto')
      }
    }

    $this.toggleClass('opened')
  })
}

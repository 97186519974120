import Swiper from 'swiper/js/swiper.min.js'

let teaserInit = new Swiper('.dhsv_vc_teaser_boxes .swiper-container', {
  effect: 'slide',
  slidesPerView: 1,
  slidesPerGroup: 1,
  allowTouchMove: true,
  loop: false,
  autoHeight: true,
  pagination: {
    el: '.teaser_pagination',
    clickable: true,
  },
  breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // when window width is >= 992px
    992: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 80,
      allowTouchMove: false,
    },
  },
})

$(window).load(function() {
  if ($('.dhsv_vc_teaser_boxes').length) {
    teaserInit.init()
    teaserInit.update()
  }
})

import $ from 'jquery'
import Sticky from 'sticky-js'

let illustrationHeight = 0

$(document).ready(function() {
  $('.dhsv_vc_sticky_intro_container').each(function() {
    let $this = $(this)
    let defaultImage = $(this)
      .find('.js-sticky-item:first-of-type')
      .data('image')
    $(this)
      .find('.mockup__content')
      //.css('background-image', 'url(' + defaultImage + ')') //set default image for placeholder
      .append(`<img src="${defaultImage}" alt="">`)

    $(this)
      .find('.js-sticky-item')
      .each((i, el) => {
        $this.find('.js-dynamic-illustration').append(
          `<div class="js-pane" /* style="background-image: url('${$(el).data(
            'image'
          )}')*/">
<img src="${$(el).data('image')}" alt="" class="desktop_image">
</div>`
        )
      })
  })
})

$(window).on('load', function() {
  $('.js-pane').each(function() {
    let imgHeight = $(this)
      .find('.desktop_image')
      .innerHeight()

    if (imgHeight > illustrationHeight) {
      illustrationHeight = imgHeight
    }
  })

  $('.sticky-intro__illustration').css('height', illustrationHeight)

  if (!window.location.hash) {
    window.scrollTo(0, 0)
  }
  setTimeout(function() {
    dhsv_vc_sticky_intro_container()
  }, 500)
})

function dhsv_vc_sticky_intro_container() {
  $('.dhsv_vc_sticky_intro_container').each(function() {
    let $this = $(this)
    new Sticky('.js-illustration')
    var sticky = new Sticky('.js-illustration')

    const $panes = $(this).find('.js-pane')

    $(window).on('scroll', () => {
      //$(window).trigger('resize')
      let activePart = 0
      $(this)
        .find('.js-sticky-item')
        .each((i, el) => {
          let offset =
            $(window).scrollTop() -
            $(el).offset().top +
            $(window).outerHeight() / 3
          if (offset > 0) {
            activePart++
          }

          $(el)[(offset > 0 ? 'add' : 'remove') + 'Class']('is-active')
          offset > 0
            ? $this.find('.mockup__content > img').css('opacity', 0)
            : $this.find('.mockup__content > img').css('opacity', 1)
        })

      const $activePane = $(this)
        .find('.js-pane')
        .eq(activePart - 1)

      if ($panes.not($activePane).hasClass('is-active')) {
        $panes.removeClass('is-active')
      }

      if (activePart > 0) {
        if (!$activePane.hasClass('is-active')) {
          $activePane.addClass('is-active')
        }
      }
    })

    $(this)
      .find('.toggler_button')
      .on('click', '.btn', function() {
        setTimeout(function() {
          sticky.update()
        }, 500)
      })
  })
}

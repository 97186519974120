import React from 'react'

const Marker = () => {
  return (
    <div className="marker">
      <div className="pin" />
      <div className="pulse" />
    </div>
  )
}

export default Marker

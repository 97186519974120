function vc_headline() {
  const $wrapper = $('.toggler')
  let visibleHeight = 0
  $wrapper.each(function() {
    const offsetTop = $(this).offset().top
    let tagOffset
    if ($(this).find('span[id^=more]').length) {
      tagOffset = $(this)
        .find('span[id^=more]')
        .offset().top
      $(this).css('max-height', tagOffset - offsetTop)
    } else {
      $wrapper.find('p:lt(1)').each(function() {
        visibleHeight += $(this).outerHeight(true, true)
      })
      $(this).css('max-height', visibleHeight)
    }
  })
}

let w = 0

$(window).load(function() {
  vc_headline()
  w = $(window).width()
})

$(window).resize(function() {
  if (w != $(window).width()) {
    vc_headline()
    w = $(window).width()
  }
})

$(document).ready(init_anilines())
$(window).on('resize', function() {
  init_anilines()
})

//Line animation
function init_anilines() {
  $('.scroll > span').each(function(index, element) {
    var $aniline = $(element)
    var scrollPercentage = undefined
    var velocity = 0.65
    $(window).on('scroll', function() {
      var offset = $aniline.offset().top - $(window).height() / 1.5
      if ($(window).scrollTop() > offset) {
        scrollPercentage =
          ($(window).scrollTop() - offset) / $aniline.height() * velocity
        if (scrollPercentage > 1) {
          $aniline.find('span').css('height', $aniline.height())
        } else {
          $aniline
            .find('span')
            .css('height', $aniline.height() * scrollPercentage)
        }
      } else {
        $aniline.find('span').css('height', 0)
      }
    })
  })

  $('.dhsv_vc_headline.align-left').each(function() {
    let contentHeight = $(this)
      .find('.dhsv_vc_headline__titles')
      .outerHeight()
    $(this)
      .find('.scroll')
      .css('height', contentHeight - 10 + 'px')
  })
}

//Slide Down/Up content
// function vc_toggle_content() {
//   $('.vc_toggle_content').each(function() {
//     let textCount = $('.toggler').find('p').length
//     if (textCount < 2) {
//       $(this)
//         .find('.toggler_button')
//         .css('display', 'none')
//       $(this).addClass('disable')
//     } else {
//       $(this)
//         .find('.toggler_button')
//         .css('display', 'block')
//       $(this).removeClass('disable')
//     }
//   })
// }
//
// $(window).load(function() {
//   vc_toggle_content()
// })

$('.vc_toggle_content').on('click', '.toggler_button', function() {
  var $wrapper = $(this).closest('.vc_toggle_content')
  var togglerHeight = $wrapper.find('.toggler_container').outerHeight()
  $wrapper.toggleClass('disable')
  if ($wrapper.hasClass('disable')) {
    $wrapper.find('.toggler').css('max-height', togglerHeight)
    $wrapper.find('.toggler_button span').text('Weniger')
  } else {
    //vc_headline()
    const $toggler = $(this).closest('.toggler')
    let visibleHeight = 0
    const offsetTop = $toggler.offset().top
    let tagOffset
    let moreTag = $toggler.find('span[id^=more]')
    if (moreTag.length) {
      tagOffset = moreTag.offset().top
      $toggler.css('max-height', tagOffset - offsetTop)
    } else {
      $toggler.find('p:lt(1)').each(function() {
        visibleHeight += $(this).outerHeight(true, true)
      })
      $toggler.css('max-height', visibleHeight)
    }
    $wrapper.find('.toggler_button span').text('Weiterlesen')
  }
})

import Swiper from 'swiper/js/swiper.min.js'

let testimonialsInit = new Swiper('.testimonial_photo', {
  effect: 'fade',
  allowTouchMove: true,
  slidesPerView: 1,
  loop: false,
  autoHeight: true,
  pagination: {
    el: '.testimonials_pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.testimonials_next',
    prevEl: '.testimonials_prev',
  },
  thumbs: {
    swiper: {
      el: '.testimonial_info',
      slidesPerView: 1,
      effect: 'fade',
      allowTouchMove: false,
      autoHeight: true,
    },
  },
})

$(window).load(function() {
  if ($('.dhsv_vc_testimonials_slider').length) {
    testimonialsInit.init()
    testimonialsInit.update()
  }
  if (
    $('.testimonial_photo .swiper-slide:not(.swiper-slide-duplicate)').length ==
    1
  ) {
    $('.dhsv_vc_testimonials_slider .swiper-button-next').addClass('disabled')
    $('.dhsv_vc_testimonials_slider .swiper-button-prev').addClass('disabled')
    $('.dhsv_vc_testimonials_slider .swiper-pagination').addClass('disabled')
  }
})

import Swiper from 'swiper/js/swiper.min.js'

$('.dhsv_vc_scrollbar_slider').each(function() {
  let id = $(this).attr('id')
  let $dragger = $('.scrollbar_scroll[data-slider="' + id + '"]')
  let swiper = new Swiper($(this), {
    init: false,
    autoHeight: false,
    spaceBetween: 35,
    slidesPerView: 1,
    allowTouchMove: true,
    scrollbar: {
      el: $dragger,
      hide: false,
      draggable: true,
      dragSize: 200,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
  })
  swiper.init()
})

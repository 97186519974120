import Swiper from 'swiper/js/swiper.min.js'

let magazinInit = new Swiper('.dhsv_vc_magazin_teaser .swiper-container', {
  effect: 'slide',
  slidesPerView: 1,
  slidesPerGroup: 1,
  allowTouchMove: true,
  loop: false,
  autoHeight: true,
  pagination: {
    el: '.magazin_pagination',
    clickable: true,
  },
  on: {
    init: function() {
      let activeIndex = this.activeIndex
      this.slides
        .eq(activeIndex)
        .addClass('swiper-slide-visible_magazin')
        .next()
        .addClass('swiper-slide-visible_magazin')
        .next()
        .addClass('swiper-slide-visible_magazin')
    },
    slideChange: function() {
      let activeIndex = this.activeIndex
      this.slides.removeClass('swiper-slide-visible_magazin')
      this.slides
        .eq(activeIndex)
        .addClass('swiper-slide-visible_magazin')
        .next()
        .addClass('swiper-slide-visible_magazin')
        .next()
        .addClass('swiper-slide-visible_magazin')
    },
  },
  breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 2,
    },
    // when window width is >= 992px
    992: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      allowTouchMove: false,
    },
  },
})

$(window).load(function() {
  if ($('.dhsv_vc_magazin_teaser').length) {
    magazinInit.init()
    magazinInit.update()
  }
})
